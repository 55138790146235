import * as React from 'react'
import linkResolver from '../utils/prismic/link-resolver'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

const PreviewPage = (props) => {
    return (
        <div>
            <h1>Loading preview…</h1>
        </div>
    )
}

export default withPrismicPreviewResolver(PreviewPage, [{
    repositoryName: 'playbrush',
    linkResolver,
    config: {
        autoRedirect: true,
    },
}]);
